import React, {useEffect, useMemo, useState} from "react";
import {ContentBlocksView} from "./content/ContentBlocksView";
import {ChevronDown, Star} from "react-feather";
import {Pin} from "@webng/icons-common";
import EventSharing from "./event/EventSharing";
import {useCommentsMode, useEventSharingType, useLayoutAuthorPosition} from "./LiveblogRenderContext";
import {MultiMediaContentBlockViewExportedProps} from "./content/MultiMediaContentBlockView";
import {useMasonryProperties} from "./MasonryContainer";
import {NiceDateTimeHighlights} from "./NiceDateTimeHighlights";
import classNames from "classnames";
import {EventExtension} from "./extensions/Extension";
import {IEvent, IGame, ITag} from "@webng-types/write-model";
import {EventExtensionRenderer} from "./extensions/EventExtensionRenderer";
import {EventAuthor} from "./event/EventAuthor";
import {EventDateTime} from "./event/EventDateTime";
import {EventSportIcon} from "./event/EventSportIcon";
import useBreakpoints from "@webng/react-app-common/src/utils/useBreakpoints";
import {commentsEnabled} from "./converter/commentsEnabled";
import {useTranslation} from "react-i18next";
import { TagImage } from "./TagImage";

export interface LiveblogEventRowExportedProps extends MultiMediaContentBlockViewExportedProps {
  readonly eventExtension?: EventExtension
}

export interface LiveblogEventRowProps extends LiveblogEventRowExportedProps {
  readonly game: IGame
  readonly event: IEvent
  readonly enableComments?: boolean
  readonly isPreview?: boolean
}

// const breakpoints: [string, number][] = [
//   ["sm", 480],
//   ["md", 1200],
//   ["lg", Infinity]
// ];

function mergeRefs<T = any>(
  refs: Array<React.MutableRefObject<T> | React.LegacyRef<T>>
): React.RefCallback<T> {
  return (value) => {
    refs.forEach((ref) => {
      if (typeof ref === "function") {
        ref(value);
      } else if (ref != null) {
        (ref as React.MutableRefObject<T | null>).current = value;
      }
    });
  };
}

function useTags(definedTags: ITag[]|undefined, eventTagIds: string[]|undefined): ITag[] {
  return useMemo(() => {
    if(eventTagIds && definedTags) {
      return eventTagIds.map(tag_id => {
        return definedTags.find(t => t._id === tag_id) || null
      }).filter(tag => tag !== null) as ITag[]
    }
    else {
      return []
    }
  }, [definedTags, eventTagIds])
}

export function LiveblogEventRow({game, event, eventExtension, isPreview, ...props}: LiveblogEventRowProps)  {
  const {ref, css} = useMasonryProperties()
  const {t} = useTranslation("liveblog")

  const [foldOut, setFoldOut] = useState(true)

  // reset foldout state whenever the foldout flag changes
  useEffect(() => {
    setFoldOut(true)
  }, [event.fold_out])

  const tags = useTags(game.event_tags?.filter(tag => tag.show_in_event ?? true)?.sort((a,b) => a.name.localeCompare(b.name)), event.tag_ids)
  const editor = useMemo(() => {
    // construct editor to use virtual editor information, if an editor is set
    // (i.e. prefer `local_*` fields over the originals)
    const virtualEditor = event.editor ? {
      ...event.editor,
      name: event.editor.local_name ?? event.editor.name,
      image: event.editor.local_image ?? event.editor.image
    } : undefined
    if(virtualEditor) return virtualEditor;

    // if no editor is set, use the usual lookup by user_id, but prefer the original fields over the `local_*` fields
    const fallbackEditor = game.editors?.find(e => e._id === event.user_id)
    if(fallbackEditor) {
      return {
        ...fallbackEditor,
        local_byline: undefined
      }
    }

    return undefined
  }, [event.editor, event.user_id, game.editors])

  const isHighlightEvent = event.highlight === "inplace" || event.highlight === "sticky"
  const isStickyEvent = event.highlight === "sticky" || event.highlight === "stickyNoHighlight"
  const eventSharingType = useEventSharingType();
  const commentsMode = useCommentsMode();
  const layoutAuthorPosition = useLayoutAuthorPosition();
  const {ref: sizeRef, breakpoint: breakpointClassName} = useBreakpoints([
    ["", 460],
    ["tik4-event--xs", 576],
    ["tik4-event--xs tik4-event--sm", 768],
    ["tik4-event--xs tik4-event--sm tik4-event--md", 992],
    ["tik4-event--xs tik4-event--sm tik4-event--md tik4-event--lg", 1200],
    ["tik4-event--xs tik4-event--sm tik4-event--md tik4-event--lg tik4-event--xl", Infinity]
  ]);


  let eventHighlightClassName = '';
  if (isStickyEvent) {
    eventHighlightClassName += ' tik4-event--pin';
  }
  if (isHighlightEvent) {
    eventHighlightClassName += ' tik4-event--highlight';
  }


  // this class has no styles, is only used so a meaningful change occurs when configuration changes for
  // height recalculations
  const authorClassNames = classNames(
    "tik4-author",
    {
      "tik4-author--author-top": layoutAuthorPosition === "top",
      "tik4-author--author-bottom-left": layoutAuthorPosition === "bottomLeft",
      "tik4-author--author-bottom-right": layoutAuthorPosition === "bottomRight",
      "tik4-author--author-off": layoutAuthorPosition === "off",
    }
  )

  const eventClassNames = classNames(
    "tik4-event",
    {
      [`${breakpointClassName}`]: breakpointClassName,
      "tik4-event--sport": event.sport_event,
      [`tik4-event--sport--${event.sport_event?.event_type?.replace(".", "-")}`]: event.sport_event?.event_type,
      [`${eventHighlightClassName}`]: eventHighlightClassName,
      "tik4-event--hlim": event.fold_out && foldOut,
      [`${tags.map((tag) => `tik4-event--tag-${tag.slug}`).join(" ")}`]: tags.length > 0
    }
  )

  return <div ref={mergeRefs([ref,sizeRef])} style={css} className={eventClassNames}
              data-tickaroo-event-id={event.local_id}>

    <EventExtensionRenderer event={event} Extension={eventExtension?.EventTop}/>
    <div className="tik4-event__timeline"/>
    <div className="tik4-event__body">

      {(game.use_event_tags) && tags.length > 0 &&
        <div className="tik4-tags">
          {tags.map((tag) => {
              return <div key={tag._id} className={`tik4-tag tik4-tag--${tag.slug || 'no-id'}`}>
                <TagImage image={tag.image} className="tik4-tag__img" />
                <div className="tik4-tag__name">{tag.name}</div>
              </div>
            }
          )}
        </div>
      }
      <div className="tik4-meta">
        <div className="tik4-meta__timeline">
          { event.sport_event && event.sport_event.event_type &&
            <div className="tik4-meta__timeline__si">
              <EventSportIcon className="tik4-icon tik4-icon--sport tik4-meta__timeline__si__i" block={event.sport_event} />
            </div>
          }
          {(isHighlightEvent || isStickyEvent) &&
            <div className="tik4-meta__timeline__svg-icon-container">
              {isHighlightEvent && <Star className="tik4-icon tik4-icon--star tik4-meta__timeline__svg-icon"/>}
              {isStickyEvent && <Pin className="tik4-icon tik4-icon--pin tik4-meta__timeline__svg-icon"/>}
            </div>
          }
          <div className="tik4-meta__timeline__dot">
          </div>
        </div>

        <EventDateTime event={event}>
          <React.Fragment>
            <NiceDateTimeHighlights isHighlightEvent={isHighlightEvent} isStickyEvent={isStickyEvent} sportEvent={event.sport_event} />
          </React.Fragment>
        </EventDateTime>

      </div>

      {editor && <EventAuthor className={authorClassNames} editor={editor} showByline={true}/>}

      <ContentBlocksView
        game={game}
        event={event}
        eventExtension={eventExtension}
        comments={commentsMode !== "disabled" && commentsEnabled(event.comments) ? ({
          version: event.version!,
          channelId: event.local_id!,
          ownerId: game.owner!._id!
        }) : undefined}
        isPreview={isPreview}
        {...props} />

      {eventSharingType !== "off" && <EventSharing eventId={event.local_id!} type={eventSharingType}/>}
    </div>

    {(event.fold_out && foldOut) &&
      <div className="tik4-hlim">
        <button className="tik4-hlim__link tik4-link" onClick={() => setFoldOut(false)}><ChevronDown className="tik4-icon tik4-icon--mr"/>{t('liveblog.event.fold_out.button')}</button>
        {/* <Link onClick={() => setFoldOut(false)} >Fold Out</Link> */}
      </div>
    }

    <EventExtensionRenderer event={event} Extension={eventExtension?.EventBottom}/>
  </div>;
}
